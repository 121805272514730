import {Container, List, ListItem, ListItemAvatar} from "@mui/material"
import Typography from "@mui/material/Typography"
import {AddRounded} from "@mui/icons-material"

export function OUDQuotes() {
	return (
		<Container
			maxWidth="md"
			sx={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
				//marginTop: "2rem"
			}}
		>
			<List>
				<ListItem sx={{alignItems: "baseline"}}>
					<QuoteIcon />
					<Typography
						variant="homeOUDQuote"
						align="left"
						sx={{ color:"#263031" }}
					>
						"... <Typography variant="homeOUDQuote" sx={{color: "#02C1D4", fontWeight: "700"}}>improved accuracy and efficiency</Typography> for both medical students and policymakers."
					</Typography>
				</ListItem>
				<ListItem sx={{alignItems: "baseline"}}>
					<QuoteIcon />
					<Typography
						variant="homeOUDQuote"
						align="left"
						sx={{ color:"#263031" }}
					>
						"For policymakers, the odds of <Typography variant="homeOUDQuote" sx={{color: "#02C1D4", fontWeight: "700"}}> answering correctly were 3.65 times higher </Typography> for Refzoom vs non-Refzoom searches..."
					</Typography>
				</ListItem>
				<ListItem sx={{alignItems: "baseline"}}>
					<QuoteIcon />
					<Typography
						variant="homeOUDQuote"
						align="left"
						sx={{ color:"#263031" }}
					>
						"... policy-makers reported <Typography variant="homeOUDQuote" sx={{color: "#02C1D4", fontWeight: "700"}}> increased  levels  of confidence</Typography> and<Typography variant="homeOUDQuote" sx={{color: "#02C1D4", fontWeight: "700"}}> higher levels of satisfaction</Typography>..."
					</Typography>
				</ListItem>
			</List>
			<Typography variant={"oudQuoteCitation"} align={"left"} sx={{fontStyle: "italic", alignSelf: "start", marginLeft: "2rem"}} maxWidth={"60%"}>
				- Giroux, K. et al. (2025) <a href={"https://www.tandfonline.com/doi/full/10.1080/00952990.2025.2450431"}>‘Speed and accuracy of online searches by legislators and medical students using RefBin’s opioid use disorder database’</a>, The American Journal of Drug and Alcohol Abuse
			</Typography>
		</Container>
	)
}

function QuoteIcon() {
	return (
		<ListItemAvatar
			sx={{
				color: "notes.toggleChildrenIcon",
				position: "relative",
				top: "0.5rem"
			}}
		>
			<AddRounded
				fontSize={"large"}
				sx={{
					rotate: "0deg",
					animationDuration: "2s",
					animationIterationCount: "infinite",
					"&:hover": {
						animationName: "rotation",
						animationTimingFunction: "linear"
					}
				}}
			/>
		</ListItemAvatar>
	)
}