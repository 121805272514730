import {Accordion, AccordionDetails, AccordionSummary, TextField, Typography} from "@mui/material"
import {Edit, EditOff} from "@mui/icons-material"
import {useEffect, useRef, useState} from "react"
import axios from "axios"

export default function AuthorInviteInput({inviteKey, setInviteKey}) {
	const [accordionExpanded, setAccordionExpanded] = useState(false)
	const [validKeyCheck, setValidKeyCheck] = useState(false)
	const [invalidKeyMsg, setInvalidKeyMsg] = useState("")
	const keyInputFocusRef = useRef()

	const validateKeyInput = (inputValue) => {
		axios.get(`/api/license/validate/${inputValue}`)
			.then((res) => {
				if(res.status === 200) {
					setValidKeyCheck(true)
					setInvalidKeyMsg("")
				}
			})
			.catch((axiosRes) => {
				console.error("KEY DID NOT VALIDATE: ", axiosRes)
				const { response } = axiosRes
				if(response.status === 403) {
					setInvalidKeyMsg("This key is invalid")
				} else {
					setInvalidKeyMsg("Something went wrong attempting to validate this key, try again")
				}
				setValidKeyCheck(false)
			})
	}

	const handleInput = (event) => {
		setInviteKey(event.target.value)
		if(event.target.value.length >= 30) {
			validateKeyInput(event.target.value.trim())
		} else if (inviteKey && invalidKeyMsg) {
			setInvalidKeyMsg("This does not appear to be a valid key, did you copy the correct value?")
		}
	}

	useEffect(() => {
		if(accordionExpanded && keyInputFocusRef.current) {
			keyInputFocusRef.current.focus()
		}
	}, [accordionExpanded])

	return (
		<Accordion
			onChange={() => setAccordionExpanded(!accordionExpanded)}
			sx={{
				// this disables the divider bar for the Accordion component
				"&:before": {
					display: "none",
				},
				"&:hover": {
					borderColor: "rgb(85,85,85)"
				},
				boxShadow: "none",
				border: "1px solid",
				borderColor: `${ accordionExpanded ? "rgb(85,85,85)" : "rgba(0, 0, 0, 0.23)"}`,
				borderRadius: "4px"
			}}

		>
			<AccordionSummary expandIcon={accordionExpanded ? <EditOff /> : <Edit />}>
				<Typography variant={"tunedSubtitle2"} sx={{ color: "rgba(0,0,0, 0.6)", textAlign: "center", fontStyle: "italic" }}>
					{accordionExpanded ? "Enter your invite code:" : "Click here to enter your invite code"}
				</Typography>
			</AccordionSummary>
			<AccordionDetails>
				<TextField
					onKeyDown={(e) => e.stopPropagation()}
					fullWidth
					className="tunedTextField"
					id="invitekey"
					label="Invite Code"
					onChange={handleInput}
					value={inviteKey}
					inputRef={keyInputFocusRef}
					error={inviteKey && inviteKey.length > 3 && !validKeyCheck}
					helperText={inviteKey && inviteKey.length > 3 && !validKeyCheck ? invalidKeyMsg : ""}
				/>
			</AccordionDetails>
		</Accordion>
	)
}