import {Button, Container, Stack, Card, CardHeader, CardActions} from "@mui/material"
import Typography from "@mui/material/Typography"
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined"
// import {OUDRequestForm} from "./OUDRequestForm"

export default function OUDContact() {

	const steps = [
		"Sign up for a free account.",
		"Enter your name, email address, and why you're interested when prompted.",
		"We will send a link to your account for access to OUD knowledge base."
	]

	return (
		<Container maxWidth="full"
			sx={{
				//marginTop: "1rem",
				background: "#fff",
				p: 0,
				"&.MuiContainer-root": {padding: 0}
			}}
		>
			<Container maxWidth="xl" sx={{"&.MuiContainer-root": {padding: 0}}}>

				<Typography
					variant="h1"
					align="left"
					sx={{
						fontFamily: "Inter Tight",
						fontWeight: "700",
						fontSize: "2rem",
						lineHeight: "1.2",
						letterSpacing: "-1.5",
						// marginLeft: "auto",
						// marginRight: "auto",
						// maxWidth: "75%",
					}}
				>
					Are you a policy maker, family member, researcher and want to learn more about Opioid Use Disorder as a disease?
				</Typography>
				<Typography
					variant="h2"
					align="center"
					sx={{
						marginTop: "1rem",
						fontFamily: "Inter",
						fontSize: "1.25rem",
						lineHeight: "1.5",
						marginLeft: "auto",
						marginRight: "auto",
						maxWidth: "75%",
					}}
				>
					Access the OUD knowledge base.
				</Typography>
				<Container maxWidth={"lg"} sx={{margin: "2rem auto ", "&.MuiContainer-root": {padding: 0}}}>
					<Stack gap={1} direction={"row"}>
						{ steps.map((step, i) => (
							<Card key={i} sx={{flexBasis: "100%", border: "2px solid #02B3C5"}} elevation={3}>
								<CardHeader
									title={`Step ${i+1}.`}
									titleTypographyProps={{
										sx: {
											color:"#02B3C5",
											fontWeight:"600",
											fontSize:"1.2rem"
										}
									}}
									subheader={step}
									sx={{
										"& .MuiCardHeader-action": {
											alignSelf: "center"
										}
									}}
								/>
								{ i === 0 &&
									<CardActions>
										<Button
											startIcon={<AccountCircleOutlinedIcon />}
											onClick={() =>
												document.getElementById("pricing").scrollIntoView({behavior: "smooth", block: "start"})
											}
											variant={"contained"}
											size="large"
											sx={{
												color: "white",
												borderRadius: "48px",
											}}
										>

											Sign Up

										</Button>
									</CardActions>

								}
							</Card>
						))}
					</Stack>
				</Container>
				{/*<OUDRequestForm />*/}
			</Container>
		</Container>
	)
}
