import { useState } from "react"
import axios from "axios"
import { Box, Button, TextField } from "@mui/material"
import { setUser } from "../../reducers/userSlice"
import { useDispatch } from "react-redux"
import {setWorkspaceThunk} from "../../reducers/workspaceSlice"
import {useSnackbar} from "notistack"

export default function NewWorkspaceForm({ afterCreate }) {
	const dispatch = useDispatch()
	const {enqueueSnackbar} = useSnackbar()
	const [title, setTitle] = useState("")
	const [description, setDescription] = useState("")

	const handleSubmit = (event) => {
		event.preventDefault()
		axios
			.post("/api/workspace", {
				title,
				description,
			})
			.then(({ data }) => {
				const justCreatedWorkspace = data.newWorkspace
				const justCreatedUserWorkspace = data.userWorkspaces.find((ws) => ws.workspace.id === justCreatedWorkspace.id)
				const workspaceObject = {
					...justCreatedWorkspace,
					permissions: { read: true, write: true, admin: true },
					ownerIsFreeTier: justCreatedUserWorkspace.ownerIsFreeTier
				}
				const updatedUser = data.user
				dispatch(setWorkspaceThunk(workspaceObject))
				dispatch(setUser(updatedUser))
				afterCreate(workspaceObject)
			})
			.catch((error) => {
				console.error("ERROR CREATING NEW WORKSPACE: ", error)
				if(error?.response?.data){
					enqueueSnackbar(error.response.data, {variant: "error", autoHideDuration: 3000})
				} else {

					enqueueSnackbar("Something went wrong while trying to create a new Workspace, please try again.", {variant: "error", autoHideDuration: 3000})
				}
			})
	}
	return (
		<form onSubmit={(e) => handleSubmit(e)}>
			<Box  sx={{ display: "flex", flexDirection: "column", padding: "10px" }}>
				<TextField
					fullWidth
					required
					id="title"
					label="Title"
					onChange={(e) => setTitle(e.target.value)}
					value={title}
				/>
				<TextField
					fullWidth
					multiline
					rows={3}
					required
					id="description"
					label="Description"
					onChange={(e) => setDescription(e.target.value)}
					value={description}
					sx={{
						margin: "1rem 0"
					}}
				/>
				<Button variant={"contained"} type='submit'>
					Create Workspace
				</Button>
			</Box>
		</form>

	)
}
