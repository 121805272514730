import {Button, Container, TextField} from "@mui/material"
import axios from "axios"
import {useState} from "react"
import {useSnackbar} from "notistack"


export function OUDRequestForm({afterSend}) {
	const [formData, setFormData] = useState({name: "", email: "", message: ""})
	const { enqueueSnackbar } = useSnackbar()

	const handleSend = () => {
		const messageToSend = {
			...formData
		}

		axios
			.post("/api/email/oud-contact", messageToSend)
			.then(() => {
				enqueueSnackbar("Message sent!", { variant: "info" })
			})
			.catch(error => {
				console.error(error)
				enqueueSnackbar("Something went wrong. Please try again.", { variant: "error" })
			})
			.finally(() => {
				setFormData({name: "", email: "", message: ""})
				if(afterSend) {
					afterSend()
				}
			})
	}

	return (
		<Container maxWidth="md">
			<form onSubmit={(e) => {
				e.preventDefault()
				handleSend()
			}}>
				<TextField
					fullWidth
					required
					variant="outlined"
					label="Your Name"
					value={formData.name}
					onChange={(e) => {
						setFormData(prevState => {
							return {
								...prevState,
								name: e.target.value
							}
						})
					}}
					sx={{ margin: "16px 0", background: "white" }}
				/>
				<TextField
					fullWidth
					required
					variant="outlined"
					label="Email Address"
					value={formData.email}
					type="email"
					onChange={(e) => {
						setFormData(prevState => {
							return {
								...prevState,
								email: e.target.value
							}
						})
					}}
					sx={{ margin: "0 0", background: "white" }}
				/>
				<TextField
					fullWidth
					multiline
					rows={5}
					variant="outlined"
					label="Why are you interested?"
					value={formData.message}
					onChange={(e) => {
						setFormData(prevState => {
							return {
								...prevState,
								message: e.target.value
							}
						})
					}}
					sx={{ margin: "16px 0", background: "white" }}
				/>
				<Button type='submit' variant="contained" sx={{ width: "100%" }}>Send</Button>
			</form>
		</Container>
	)
}